import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";

import Login from "./containers/Login";
import Inbox from "./containers/Inbox";
import Dashboard from "./containers/Dashboard";
import CreateInboxForm from "./containers/Inbox/CreateInbox";
import EditInboxForm from "./containers/Inbox/Editinbox";
import Advertiser from "./containers/Advertiser";
import PromoCode from "./containers/PromoCode";
import CreatePromoCode from "./containers/PromoCode/CreatePromoCode";
import EditPromoCode from "./containers/PromoCode/EditPromoCode";
import CreateAdvertiserIndividual from "containers/Advertiser/CreateAdvertiserIndividual";
import CreateAdvertiserCompany from "containers/Advertiser/CreateAdvertiserCompany";
import UpdateAdvertiserCompany from "containers/Advertiser/UpdateAdvertiserCompany";
import UpdateAdvertiserIndividual from "containers/Advertiser/UpdateAdvertiserIndividual";
import CandidateList from "./containers/CandidateList";
import ManageUser from "./containers/ManageUser";
import CreateUser from "./containers/ManageUser/CreateUser";
import ApplicantRecommendationAdvertiser from "./containers/ApplicantRecommendationAdvertiser";
import ApplicantRecommendationAdvertiserCandidate from "./containers/ApplicantRecommendationAdvertiser/CandidateList";
import ApplicantRecommendationCandidate from "./containers/ApplicantRecommendationCandidate";
import ApplicationRecommendationCandidatePost from "./containers/ApplicantRecommendationCandidate/PostList";
import ManageBannerPopUp from "./containers/ManageBannerPopUp";
import ManageBannerInHouse from "containers/ManageBannerInHouse";
import CreatePopupForm from "./containers/ManageBannerPopUp/CreatePopUp";
import CreateBannerForm from "./containers/ManageBannerInHouse/CreateBanner";
import PreviewBanner from "./containers/ManageBannerInHouse/preview";
import Post from "containers/Post";
import CreatePost from "containers/Post/CreatePost";
import UpdatePost from "containers/Post/UpdatePost";
import PostComplete from "containers/Post/CreatePost/complete";
import PostRedirect from "containers/Post/CreatePost/redirect";
import Billing from "containers/Billing";
import BillingDetails from "containers/Billing/BillingDetails";

import CPSalesReport from "containers/AnalyticReport/CPSalesReport";
import SalesReport from "containers/AnalyticReport/SalesReport";
import RevenueReport from "containers/AnalyticReport/RevenueReport";
import InHouseAdsReport from "containers/AnalyticReport/InHouseAdsReport";
import SalesReportByCategory from "containers/AnalyticReport/SalesReportByCategory";
import MonthlySalesReport from "containers/AnalyticReport/MonthlySalesReport";
import SCSalesReport from "containers/AnalyticReport/SCSalesReport";
import UnutilisedReport from "containers/AnalyticReport/UnutilisedReport";
import ForfeitReport from "containers/AnalyticReport/ForfeitReport";

import AuditUser from "containers/AuditLog/Users";
import AuditOrder from "containers/AuditLog/Orders";
import AuditCustomer from "containers/AuditLog/CustomerData";
import AuditPayment from "containers/AuditLog/PaymentMethods";
import AdminActions from "containers/AuditLog/AdminActions";
import InvoiceReport from "containers/AnalyticReport/InvoiceReport";
import ProfomaInvoiceReport from "containers/AnalyticReport/ProfomaInvoiceReport";
import SalesReportByProduct from "containers/AnalyticReport/SalesReportByProduct";

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			!true ? <Redirect to="/login" /> : <Component {...props} />
		}
	/>
);

function CustomRouter({ location, ...props }) {
	return (
		<Switch>
			<Route exact path="/login" component={Login} initialPath />
			<PrivateRoute path="/dashboard" component={Dashboard} />
			<PrivateRoute path="/inbox/CreateInbox" component={CreateInboxForm} />
			<PrivateRoute path="/inbox/EditInbox/:id" component={EditInboxForm} />
			<PrivateRoute path="/inbox" component={Inbox} />
			<PrivateRoute
				path="/advertiser-profile/create-individual-profile"
				component={CreateAdvertiserIndividual}
			/>
			<PrivateRoute
				path="/advertiser-profile/create-company-profile"
				component={CreateAdvertiserCompany}
			/>
			<PrivateRoute path="/advertiser-profile-company/update/:id" component={UpdateAdvertiserCompany} />
			<PrivateRoute path="/advertiser-profile-individual/update/:id" component={UpdateAdvertiserIndividual} />
			<PrivateRoute path="/advertiser-profile" component={Advertiser} />
			<PrivateRoute path="/promo-code/create" component={CreatePromoCode} />
			<PrivateRoute path="/promo-code/edit/:id" component={EditPromoCode} />
			<PrivateRoute path="/promo-code" component={PromoCode} />
			<PrivateRoute path="/candidate-list" component={CandidateList} />
			<PrivateRoute path="/manage-user/create" component={CreateUser} />
			<PrivateRoute path="/manage-user/edit/:id" component={CreateUser} />
			<PrivateRoute path="/manage-user" component={ManageUser} />
			<PrivateRoute
				path="/applicant-recommendation-advertiser/candidate/:specialization_id/:id"
				component={ApplicantRecommendationAdvertiserCandidate}
			/>
			<PrivateRoute
				path="/applicant-recommendation-advertiser"
				component={ApplicantRecommendationAdvertiser}
			/>
			<PrivateRoute
				path="/applicant-recommendation-candidate/post/:id"
				component={ApplicationRecommendationCandidatePost}
			/>
			<PrivateRoute
				path="/applicant-recommendation-candidate"
				component={ApplicantRecommendationCandidate}
			/>
			<PrivateRoute path="/banner-popup/edit/:id" component={CreatePopupForm} />
			<PrivateRoute path="/banner-popup/create" component={CreatePopupForm} />
			<PrivateRoute path="/banner-popup" component={ManageBannerPopUp} />

			<PrivateRoute path="/manage-user/edit/:id" component={CreateUser} />
			<PrivateRoute path="/manage-user" component={ManageUser} />
			<PrivateRoute
				path="/applicant-recommendation-advertiser/candidate"
				component={ApplicantRecommendationAdvertiserCandidate}
			/>
			<PrivateRoute
				path="/applicant-recommendation-advertiser"
				component={ApplicantRecommendationAdvertiser}
			/>
			<PrivateRoute
				path="/applicant-recommendation-candidate/post"
				component={ApplicationRecommendationCandidatePost}
			/>
			<PrivateRoute
				path="/applicant-recommendation-candidate"
				component={ApplicantRecommendationCandidate}
			/>
			<PrivateRoute path="/banner-popup/edit/:id" component={CreatePopupForm} />
			<PrivateRoute path="/banner-popup/create" component={CreatePopupForm} />
			<PrivateRoute path="/banner-popup" component={ManageBannerPopUp} />

			<PrivateRoute path="/sales-report" component={SalesReport} />
			<PrivateRoute
				path="/sales-report-category"
				component={SalesReportByCategory}
			/>
			<PrivateRoute
				path="/sales-report-product"
				component={SalesReportByProduct}
			/>
			<PrivateRoute path="/revenue-report" component={RevenueReport} />
			<PrivateRoute path="/in-house-ads-report" component={InHouseAdsReport} />
			<PrivateRoute
				path="/monthly-sales-report"
				component={MonthlySalesReport}
			/>
			<PrivateRoute path="/invoice-report" component={InvoiceReport} />
			<PrivateRoute path="/profoma-invoice-report" component={ProfomaInvoiceReport} />
			<PrivateRoute path="/cp-sales-report" component={CPSalesReport} />
			<PrivateRoute path="/sc-sales-report" component={SCSalesReport} />
			<PrivateRoute
				path="/unutilised-sales-report"
				component={UnutilisedReport}
			/>
			<PrivateRoute path="/forfeit-sales-report" component={ForfeitReport} />
			<PrivateRoute
				path="/banner-inhouse/edit/:id"
				component={CreateBannerForm}
			/>
			<PrivateRoute
				path="/banner-inhouse/create"
				component={CreateBannerForm}
			/>
			<PrivateRoute
				path="/banner-inhouse/preview/:id"
				component={PreviewBanner}
			/>
			<PrivateRoute path="/banner-inhouse" component={ManageBannerInHouse} />
			<PrivateRoute path="/post/create-post" component={CreatePost} />
			<PrivateRoute path="/post/update-post" component={UpdatePost} />
			<PrivateRoute path="/post/complete" component={PostComplete} />
			<PrivateRoute path="/post/redirect" component={PostRedirect} />
			<PrivateRoute path="/post" component={Post} />
			<PrivateRoute
				path="/billing/billing-details/:id"
				component={BillingDetails}
			/>
			<PrivateRoute path="/billing" component={Billing} />
			<PrivateRoute path="/audit-log/user" component={AuditUser} />
			<PrivateRoute path="/audit-log/orders" component={AuditOrder} />
			<PrivateRoute path="/audit-log/customer" component={AuditCustomer} />
			<PrivateRoute path="/audit-log/payment-method" component={AuditPayment} />
			<PrivateRoute path="/audit-log/admin-actions" component={AdminActions} />

			<Redirect from={"/"} to={"/login"} />
		</Switch>
	);
}

const mapStateToProps = (state) => ({ data: state });
export default compose(connect(mapStateToProps), withRouter)(CustomRouter);
