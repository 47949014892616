import React, { Component } from "react";
import { compose } from "redux";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import { Col, Row, Card, CardBody, Button } from "reactstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PageTitle from "components/Title/PageTitle";
import CustomTable from "components/Table";
import CustomModal from "components/Modal";
import ConfirmationModal from "components/Modal/confirmation";
import Pagination from "components/Pagination";
import FilterForm from "./Form";
import BillingAPI from "./action.js";
import moment from "moment";

import "./index.scss";

const renderDescriptionRegionText = (data) => {
	const region = data?.region?.['en']
	const region_render_text = region ? ` (${region})` : ''
	return region_render_text
}

const renderRegionText = (planId, data) => {
	const region = data?.order_plans?.find(e => e.plan_id == 15)?.region?.['en']
	const region_render_text = region ? ` (${region})` : ''
	return region_render_text
}

class Billing extends Component {
	componentDidMount = () => [this.props.getBilling(), this.props.getPackage()];

	componentDidUpdate = (pp) => {
		const { billingPages, getBilling } = this.props;
		const { filterQuery } = this.state;

		if (pp.billingPages !== billingPages) {
			getBilling(
				`${billingPages > 1 ? `&offset=${(billingPages - 1) * 10}` : ""}${
					!!filterQuery ? filterQuery : ""
				}`
			);
		}
	};

	state = {
		filterQuery: "",
	};

	onToggleGenerate = (id, context) => {
		switch (context) {
			case "proforma_invoice_number":
				return this.props.getProformaInvoice(id);
			case "invoice_number":
				return this.props.getInvoice(id);
			case "receipt_number":
				return this.props.getReceipt(id);
			default:
				break;
		}
	};

	renderRowSubComponent = ({ row }) => {
	return(
		<div className="subrow">
			<div className="each-row">
				<div className="each-item">
					Plan: <b>{row._original.package.name?.en + renderRegionText(15, row._original) || "-"}</b>
				</div>
				<div className="each-item">
					Plan Details: <b>{row._original.order_plans ? row._original.order_plans.map(orderPlane => orderPlane.plan_name.en + renderDescriptionRegionText(orderPlane)).join(", ") : "-"}</b>
				</div>
			</div>
			<div className="each-row">
				<div className="each-item">
					Promo Code: <b>{row._original.voucher.code ? row._original.voucher?.code : "-"}</b>
				</div>
				<div className="each-item">
					Payment Method: <b>{row._original.payment_method ? row?._original.payment_method : "-"}</b>
				</div>
				<div className="each-item">
                    Transaction Reference: <b>{row._original.payment_reference_number ? row?._original.payment_reference_number : "-"}</b>
				</div>
			</div>
			<div className="each-row">
				<div className="each-item">
                    Merchant Reference: <b>{row._original.payment_gateway_payload?.merchant_reference ? row?._original.payment_gateway_payload.merchant_reference : "-"}</b>
				</div>
			</div>
			<div className="each-row">
				<div className="each-item">
					Proforma Invoice:{" "}
					<span 
						className="blue-text" 
						style={{ cursor: "pointer" }}
						onClick={() => {
							this.props.getPolyInvoice(row._original, "proforma");}}
					>
						{row._original.proforma_invoice_number ? row._original.proforma_invoice_number : "-"}
					</span>
				</div>
				<div className="each-item">
					Invoice Number:{" "}
					<span 
						className="blue-text" 
						style={{ cursor: "pointer" }}
						onClick={() => {
							this.props.getPolyInvoice(row._original, "normal");}}
					>
						{row._original.invoice_number ? row._original.invoice_number : "-"}
					</span>
				</div>
				<div className="each-item">
					Receipt Number:{" "}
					<span 
						className="blue-text" 
						style={{ cursor: "pointer" }}
						onClick={() => {
							this.props.getPolyInvoice(row._original, "receipt");}}
					>
						{row._original.receipt_number ? row._original.receipt_number : "-"}
					</span>
				</div>
			</div>
		</div>
	)};

	renderSampleTable = () => {
		const { billingList, onLoad } = this.props;

		return (
			<CustomTable
				data={billingList}
				showPagination={false}
				loading={onLoad}
				columnsContent={[
					{
						Header: "Advertiser ID",
						accessorKey: "company",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.company.id}</>,
					},
					{
						Header: "Post ID",
						accessorKey: "job_ads_id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.job_ad?.platform_uuid}</>,
					},
					{
						Header: "EC ID",
						accessorKey: "id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val?.job_ad?.old_system_data?.id ?? "-"}</>,
					},
					{
						Header: "Order No.",
						accessorKey: "id",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.id}</>,
					},
					{
						Header: "Advertiser Name",
						accessorKey: "company",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.company.name}</>,
					},
					{
						Header: "Order Descriptions",
						accessorKey: "description",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val.order_plans?.map((plan) => plan.plan_name['en']).join(", ")}</>,
					},
					{
						Header: "Agent ID",
						accessorKey: "agent",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val?.company_agent?.code ?? '-'}</>,
					},
					{
						Header: "Agent Name",
						accessorKey: "agent",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>{val?.company_agent?.name ?? '-'}</>,
					},
					{
						Header: "Grand Total",
						accessorKey: "grand_total",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => <>RM {val.grand_total}</>,
					},
					{
						Header: "Published Date",
						accessorKey: "job_ad",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{val.job_ad.published_date ? moment(val.job_ad.published_date).format("DD/MM/YYYY[\n]hh:mm A") : "-"}</>
						),
					},
					{
						Header: "Last Modified Date",
						accessorKey: "updated_at",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{val.updated_at ? moment(val.updated_at).format("DD/MM/YYYY[\n]hh:mm A") : "-"}</>
						),
					},
					{
						Header: "Transaction Date",
						accessorKey: "payment_date",
						centerColumn: true,
						isStatusButton: false,
						Cell: (val) => (
							<>{val.payment_date ? moment(val.payment_date).format("DD/MM/YYYY[\n]hh:mm A") : "-"}</>
						),
					},
					{
						Header: "",
						accessorKey: "id",
						centerColumn: true,
						isStatusButton: true,
						Cell: (val) => (
							<>
								<Button
									type="link"
									color="none"
									onClick={() =>
										this.props.history.push(
											`/billing/billing-details/${val.id}`
										)
									}>
									<i className="pe-lg pe-7s-look blue-filter" />
								</Button>
							</>
						),
					},
				]}
				SubComponent={this.renderRowSubComponent}
			/>
		);
	};

	renderFilterModal = () => {
		const { getBilling, onChangeHOC, showFilterModal } = this.props;

		const customSetState = (newState) => {
			this.setState(newState);
		};

		return (
			<CustomModal
				size={"lg"}
				title="Billing Filter"
				isOpen={showFilterModal}
				onClose={() => onChangeHOC("showFilterModal", false)}>
				<FilterForm
					packageList={this.props.packageList}
					onSubmit={getBilling}
					onChangeHOC={onChangeHOC}
					handleState={customSetState}
				/>
			</CustomModal>
		);
	};

	renderEditModal = () => {
		return (
			<ConfirmationModal
				open={this.props.showEditModal}
				onClose={() => this.props.onChangeHOC("showEditModal", false)}
				onClickConfirm={() => this.props.onChangeHOC("showEditModal", false)}
				title={`Are you sure you want to edit this message? ${this.props.selectedMessageId}`}
				message="Please confirm this action."
				confirmContent="Yes, edit"
				confirmColor="danger"
			/>
		);
	};
	renderPreviewModal = () => {
		return (
			<ConfirmationModal
				open={this.props.showPreviewModal}
				onClose={() => this.props.onChangeHOC("showPreviewModal", false)}
				onClickConfirm={() => this.props.onChangeHOC("showPreviewModal", false)}
				title={`Are you sure you want to preview this message? ${this.props.selectedMessageId}`}
				message="Please confirm this action."
				confirmContent="Yes, preview"
				confirmColor="danger"
			/>
		);
	};
	renderDuplicateModal = () => {
		return (
			<ConfirmationModal
				open={this.props.showDuplicateModal}
				onClose={() => this.props.onChangeHOC("showDuplicateModal", false)}
				onClickConfirm={() =>
					this.props.onChangeHOC("showDuplicateModal", false)
				}
				title={`Are you sure you want to duplicate this message? ${this.props.selectedMessageId}`}
				message="Please confirm this action."
				confirmContent="Yes, duplicate"
				confirmColor="danger"
			/>
		);
	};
	renderDisableModal = () => {
		return (
			<ConfirmationModal
				open={this.props.showDisableModal}
				onClose={() => this.props.onChangeHOC("showDisableModal", false)}
				onClickConfirm={() => this.props.onChangeHOC("showDisableModal", false)}
				title={`Are you sure you want to disable this message? ${this.props.selectedMessageId}`}
				message="Please confirm this action."
				confirmContent="Yes, disable"
				confirmColor="danger"
			/>
		);
	};

	render = () => {
		const { onChangeHOC, onLoad, billingPages, billingCount, getBilling, selectedPostType } =
			this.props;
		const { filterQuery } = this.state;

		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => {}}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className="app-main__outer">
							<div className="app-main__inner">
								<>
									<PageTitle
										heading="Billing"
										buttons={[
											{
												color: "",
												className: "btn-outline-primary",
												onClick: () => onChangeHOC("showFilterModal", true),
												content: (
													<>
														<span>Filter</span>
													</>
												),
											},
										]}
									/>
									<div
										className="container-fluid"
										style={{ paddingBottom: "6px" }}>
										<div className="row">
											<div className="col-auto" style={{ padding: "6px" }}>
												<button
													type="button"
													className={`btn btn-outline-primary ${
														selectedPostType === "All" ? "active" : ""
													}`}
													onClick={() => {
														if (selectedPostType !== "All") {
															onChangeHOC("selectedPostType", "All");
                                                            getBilling();
														}
													}}>
													All
												</button>
											</div>
											<div className="col-auto" style={{ padding: "6px" }}>
												<button
													type="button"
													className={`btn btn-outline-primary ${
														selectedPostType === "Pending" ? "active" : ""
													}`}
													onClick={() => {
														if (selectedPostType !== "Pending") {
															onChangeHOC("selectedPostType", "Pending");
                                                            getBilling("&status=pending");
														}
													}}>
													Pending
												</button>
											</div>
											<div className="col-auto" style={{ padding: "6px" }}>
												<button
													type="button"
													className={`btn btn-outline-primary ${
														selectedPostType === "Fail" ? "active" : ""
													}`}
													onClick={() => {
														if (selectedPostType !== "Fail") {
															onChangeHOC("selectedPostType", "Fail");
                                                            getBilling("&status=payment-failed");
														}
													}}>
													Fail
												</button>
											</div>
											{/* <div className="col-auto" style={{ padding: "6px" }}>
												<button
													type="button"
													className={`btn btn-outline-primary ${
														selectedPostType === "Suspended" ? "active" : ""
													}`}
													onClick={() => {
														if (selectedPostType !== "Suspended") {
															onChangeHOC("selectedPostType", "Suspended");
															getPost("&status=archived");
														}
													}}>
													Suspended
												</button>
											</div> */}
										</div>
									</div>
									<ReactCSSTransitionGroup
										component="div"
										transitionName="TabsAnimation"
										transitionAppear={true}
										transitionAppearTimeout={0}
										transitionEnter={false}
										transitionLeave={false}>
										<Row>
											<Col md={12} style={{minWidth: 'max-content'}}>
												<Card className="main-card mb-3">
													<CardBody>
														{this.renderSampleTable()}
														<Pagination
															pageIndex={billingPages}
															totalCount={billingCount}
															onChangePage={(val) =>
																onChangeHOC("billingPages", val)
															}
														/>
													</CardBody>
												</Card>
											</Col>
										</Row>
									</ReactCSSTransitionGroup>
									{this.renderFilterModal()}
									{this.renderEditModal()}
									{this.renderPreviewModal()}
									{this.renderDuplicateModal()}
									{this.renderDisableModal()}
									{this.props.onLoad && <LoadingOverlay />}
								</>
							</div>
						</div>
					</div>
				</TemplateContainerMain>
			</>
		);
	};
}

export default compose(BillingAPI)(Billing);
