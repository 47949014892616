import { Select } from "antd";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

export default function FilterForm({
    onSubmit,
    onChangeHOC,
    handleState,
    plansList
}) {
    const [invoiceStartDate, setInvoiceStartDate] = useState(null);
    const [invoiceEndDate, setInvoiceEndDate] = useState(null);
    const [advertiserId, setAdvertiserId] = useState("");
    const [advertiser, setAdvertiser] = useState("");
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [agentName, setAgentName] = useState("");
    const [agentLeadID, setAgentLeadID] = useState("");
    const [agentLeadName, setAgentLeadName] = useState("");
    const [planId, setPlanId] = useState("");
    const [invoiceType, setInvoicecTyoe] = useState("");

    const handleClear = () => {
        setInvoiceStartDate("");
        setInvoiceEndDate("");
        setAdvertiserId("");
        setAdvertiser("");
        setInvoiceNumber("");
        setAgentName("");
        setAgentLeadID("");
        setAgentLeadName("");
        setPlanId("");
        setInvoicecTyoe("");
    };

    const generateQuery = () => {
        let queryString = "";

        if (invoiceStartDate) {
            queryString += `&invoice_start_date=${invoiceStartDate}`;
        }
        if (invoiceEndDate) {
            queryString += `&invoice_end_date=${invoiceEndDate}`;
        }
        if (invoiceNumber) {
            queryString += `&invoice_no=${invoiceNumber}`;
        }
        if (agentName) {
            queryString += `&agent_name=${agentName}`;
        }
        if (agentLeadID) {
            queryString += `&agent_lead_id=${agentLeadID}`;
        }
        if (agentLeadName) {
            queryString += `&agent_lead_name=${agentLeadName}`;
        }
        if (advertiserId) {
            queryString += `&advertiser_id=${advertiserId}`;
        }
        if (advertiser) {
            queryString += `&company_name=${advertiser}`;
        }
        if (planId) {
            queryString += `&plan_id=${planId}`;
        }
        if (invoiceType) {
            queryString += `&order_invoice_type=${invoiceType}`;
        }

        return queryString;
    };

    return (
        <>
            <CustomModalBody>
                <Form id="billing-filter-form">
                    <Row>
                        <Col lg={3}>
                            <FormGroup>
                                <Label for="invoiceStartDate">
                                    Invoice Start Date{" "}
                                </Label>
                                <Input
                                    type="date"
                                    placeholder="Enter Invoice Start Date"
                                    name="invoiceStartDate"
                                    id="invoiceStartDate"
                                    value={invoiceStartDate}
                                    onChange={(e) => {
                                        setInvoiceStartDate(e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={3}>
                            <FormGroup>
                                <Label for="invoiceEndDate">
                                    Invoice End Date{" "}
                                </Label>
                                <Input
                                    type="date"
                                    placeholder="Enter Invoice End Date"
                                    name="invoiceEndDate"
                                    id="invoiceEndDate"
                                    value={invoiceEndDate}
                                    onChange={(e) => {
                                        setInvoiceEndDate(e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={3}>
                            <FormGroup>
                                <Label for="advertiserId">Advertiser ID </Label>
                                <Input
                                    placeholder="Enter Advertiser ID"
                                    name="advertiserId"
                                    id="advertiserId"
                                    value={advertiserId}
                                    onChange={(e) => {
                                        setAdvertiserId(e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={3}>
                            <FormGroup>
                                <Label for="advertiser">Advertiser Name </Label>
                                <Input
                                    placeholder="Enter Advertiser Name"
                                    name="advertiser"
                                    id="advertiser"
                                    value={advertiser}
                                    onChange={(e) => {
                                        setAdvertiser(e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={3}>
                            <FormGroup>
                                <Label for="invoiceNumber">Invoice </Label>
                                <Input
                                    placeholder="Enter Invoice No."
                                    name="invoiceNumber"
                                    id="invoiceNumber"
                                    value={invoiceNumber}
                                    onChange={(e) => {
                                        setInvoiceNumber(e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={3}>
                            <FormGroup>
                                <Label for="agentName">Agent Name </Label>
                                <Input
                                    placeholder="Enter Agent Name"
                                    name="agentName"
                                    id="agentName"
                                    value={agentName}
                                    onChange={(e) => {
                                        setAgentName(e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={3}>
                            <FormGroup>
                                <Label for="agentLeadID">Agent Lead ID</Label>
                                <Input
                                    placeholder="Enter Agent Lead ID"
                                    name="agentLeadID"
                                    id="agentLeadID"
                                    value={agentLeadID}
                                    onChange={(e) => {
                                        setAgentLeadID(e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={3}>
                            <FormGroup>
                                <Label for="agentLeadName">
                                    Agent Lead Name{" "}
                                </Label>
                                <Input
                                    placeholder="Enter Agent Lead Name"
                                    name="agentLeadName"
                                    id="agentLeadName"
                                    value={agentLeadName}
                                    onChange={(e) => {
                                        setAgentLeadName(e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={3}>
                            <FormGroup>
                                <Label for="descriptionOfProduct">
                                    Description of Product{" "}
                                </Label>
                                <Input
                                    type="select"
                                    placeholder="Select Description of Product"
                                    name="descriptionOfProduct"
                                    id="descriptionOfProduct"
                                    value={planId}
                                    onChange={(e) => {
                                        setPlanId(e.target.value);
                                    }}
                                >
                                    <option hidden>Select Description of Product</option>
                                    {
                                        plansList && 
                                        plansList.map((plan, index) => (
                                            <option key={index} value={plan.id}>{plan.name.en}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg={3}>
                            <FormGroup>
                                <Label for="invoiceType">
                                    Invoice Type{" "}
                                </Label>
                                <Input
                                    type="select"
                                    placeholder="Select Invoice Type"
                                    name="invoiceType"
                                    id="invoiceType"
                                    value={invoiceType}
                                    onChange={(e) => {
                                        setInvoicecTyoe(e.target.value);
                                    }}
                                >
                                    <option hidden>Select Invoice Type</option>
                                    {
                                     
                                        ["Proforma", "Normal", "Receipt"].map((plan, index) => (
                                            <option key={index} value={plan.toLowerCase()}>{plan}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CustomModalBody>
            <CustomModalFooter
                rightButton={[
                    {
                        color: "danger",
                        outline: true,
                        content: "Clear All",
                        onClick: () => handleClear(),
                    },
                    {
                        color: "primary",
                        content: "Submit",
                        onClick: () => {
                            const queryString = generateQuery();
                            onSubmit(queryString);
                            handleState({ filterQuery: queryString });
                            onChangeHOC("showFilterModal", false);
                            onChangeHOC("advertiserPages", 1);
                        },
                    },
                ]}
            />
        </>
    );
}
