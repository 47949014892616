import { requestError } from "utils/requestHandler";
import { validateEmail } from "utils/helper";
import { ReturnUpForwardOutline } from "react-ionicons";

export const validateStep = (step, formData, setStep) => {
  switch (step) {
    case 0:
      if (!formData.companyId) {
        requestError("Company is required");
        return;
      }
      
      // else if (
      //   formData.isCompanyConfidential &&
      //   !formData.agentId
      // ) {
      //   requestError("Company PIC Login Email is not valid");
      //   return;
      // }

      setStep((prev) => prev + 1);
      return;

    case 1:
      if (!formData.jobTitleCN) {
        requestError("职称 (CN) is required");
        return;
      } else if (!formData.jobTitleEN) {
        requestError("Job Title (EN) is required");
        return;
      } else if (!formData.specialization) {
        requestError("Specialization is required");
        return;
      } else if (!formData.experience) {
        requestError("Experience is required");
        return;
      } else if (!parseInt(formData.vacancies)) {
        requestError("Vacancies is required");
        return;
      } else if (!formData.employmentType) {
        requestError("Employment Type is required");
        return;
      } else if (!formData.positionLevel) {
        requestError("Position Level is required");
        return;
      } else if (!formData.isSalaryNegotiable && !formData.minSalary) {
        requestError("Minimum Salary is required");
        return;
      } else if (!formData.isSalaryNegotiable && !formData.maxSalary) {
        requestError("Maximum Salary is required");
        return;
      }
      setStep((prev) => prev + 1);
      return;

    case 2:
      if(!formData.jobRequirements?.length > 0){
        requestError("Job Requirements is required");
        return;
      } else if(!formData.jobResponsibilitiesCN){
        requestError("Job Responsibilities (CN) is required");
        return;
      } else if(!formData.jobResponsibilitiesEN){
        requestError("Job Responsibilities (EN) is required");
        return;
      }
      setStep((prev) => prev + 1);
      return;

    case 3:
      if (!formData.headquarterInfo.email) {
        requestError("Headquarter Email Address is required");
        return;
      } else if (!validateEmail(formData.headquarterInfo.email)) {
        requestError("Headquarter Email Address is not valid");
        return;
      } else if (!formData.headquarterInfo.contactNumber) {
        requestError("Headquarter Company Contact Number is required");
        return;
      } else if (!formData.headquarterInfo.address) {
        requestError("Headquarter Company Address is required");
        return;
      } else if (!formData.headquarterInfo.postcode) {
        requestError("Headquarter Postcode is required");
        return;
      } else if (!formData.headquarterInfo.city) {
        requestError("Headquarter City is required");
        return;
      } else if (!formData.headquarterInfo.state) {
        requestError("Headquarter State is required");
        return;
      } else if (!formData.headquarterInfo.country) {
        requestError("Headquarter Country is required");
        return;
      } else if (formData.branches.length > 0) {
        let validationError = false;
        formData.branches.forEach((branch) => {
          if (!branch.name) {
            requestError("Branch Name is required");
            validationError = true;
            return;
          } else if (!branch.name) {
            requestError("Branch Name is required");
            validationError = true;
            return;
          } else if (!branch.contactNumber) {
            requestError("Branch Company Contact Number is required");
            validationError = true;
            return;
          // } else if (!branch.whatsappNumber) {
          //   requestError("Branch Company Whatsapp Number is required");
          //   validationError = true;
          //   return;
          } else if (!branch.address) {
            requestError("Branch Company Address is required");
            validationError = true;
            return;
          } else if (!branch.postcode) {
            requestError("Branch Postcode is required");
            validationError = true;
            return;
          } else if (!branch.city) {
            requestError("Branch City is required");
            validationError = true;
            return;
          } else if (!branch.state) {
            requestError("Branch State is required");
            validationError = true;
            return;
          } else if (!branch.country) {
            requestError("Branch Country is required");
            validationError = true;
            return;
          }
        });
        if (validationError) {
          return;
        }
      }

      setStep((prev) => prev + 1);
      return;

    default:
      return;
  }
};
