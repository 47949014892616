import React, { Component } from "react";
import { connect } from "react-redux";
import { setProfile } from "actions/profile";
import { Post, Get, Put, Delete, GetFile } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";
import { COUNTRY_MALAYSIA_ID, COUNTRY_SINGAPORE_ID } from "constants/Constants";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedPost: {},
      advertiserList: [],
      natureList: [],
      benefitList: [],
      educationLevelList: [],
      positionLevelList: [],
      packageList: [],
      planList: [],
      regionsList: [],
      agentList: [],
      countriesList: [],
      statesList: [],
      payment_key: "",
      preview: false,
      showPreviewModal: false,
    };

    onChangeHOC = (val, context) => this.setState({ [context]: val });

    getSelectedPost = (slug) => {
      Get(
        `/job-ads/single?slug=${slug}`,
        this.getSelectedPostSuccess,
        this.getSelectedPostError,
        this.load
      );
    }
    getSelectedPostSuccess = (payload) => {
      this.setState({
        selectedPost: payload.data?.[0],
        loading: false,
      });
    };
    getSelectedPostError = (error) => requestError(error);

    getBenefits = () => Get(
      `/jobads-benefits`,
      this.getBenefitsSuccess,
      error => requestError( error ),
      this.load
    )
    getBenefitsSuccess = response => this.setState({ benefitList: response.data })

    saveAsDraft = (dataToSubmit, callback, { isShowResponseMessage }) => {
      if( dataToSubmit.id ) {
        Put(
          `/job-ads/${dataToSubmit.id}`,
          dataToSubmit,
          payload => this.saveAsDraftSuccess(payload, callback, { isShowResponseMessage }),
          this.saveAsDraftError,
          this.load
        );
      } else {
        Post(
          `/job-ads`,
          dataToSubmit,
          payload => this.saveAsDraftSuccess(payload, callback, { isShowResponseMessage }),
          this.saveAsDraftError,
          this.load
        );
      }
    };
    saveAsDraftSuccess = (payload, callback, { isShowResponseMessage }) => {
        if (isShowResponseMessage) {
            requestSuccess("Job Ad saved as draft successfully.");
        }
        callback(payload.data);
    };
    saveAsDraftError = (error) => requestError(error);

    createJobAd = (dataToSubmit, paymentGatewayCallback) => {
      if( dataToSubmit.id ) {
        Put(
          `/job-ads/${dataToSubmit.id}`,
          dataToSubmit,
          (res) =>
            this.createJobAdSuccess(dataToSubmit, res, paymentGatewayCallback),
          this.createJobAdError,
          this.load
        );
      } else {
        Post(
          `/job-ads`,
          dataToSubmit,
          (res) =>
            this.createJobAdSuccess(dataToSubmit, res, paymentGatewayCallback),
          this.createJobAdError,
          this.load
        );
      }
    };
    createJobAdSuccess = (dataToSubmit, res, paymentGatewayCallback) => {
      dataToSubmit.benefits.forEach((benefit) => {
        this.createJobAdsBenefit({
          name: benefit.name,
          job_ads_id: res.data.id,
          benefits_id: benefit.benefits_id,
        });
      });

      dataToSubmit.photos.forEach((photo) => {
        this.createJobAdsGallery({
          url: photo,
          job_ads_id: res.data.id,
        });
      });

      dataToSubmit.branches.forEach((branch) => {
        this.createJobAdsBranch({
          job_ads_id: res.data.id,
          address: branch.address,
          city: branch.city,
          postcode: branch.postcode,
          state_id: branch.stateId,
          state: branch.state,
          country_id: branch.countryId,
          country: branch.country,
          branch_name: branch.name,
          contact_email: branch.email,
          contact_number: branch.contactNumberPrefix + branch.contactNumber,
          contact_whatsapp: branch.whatsappNumber,
          alternative_contact_number: branch.alternativeContactNumber
            ? branch.alternativeContactNumberPrefix +
              branch.alternativeContactNumber
            : null,
        });
      });
      this.createJobAdsOrder(
        {
          status: "backoffice-order",
          type: "jobs",
          user_id: dataToSubmit.user_id,
          is_bundle_print: dataToSubmit.is_bundle_print,
          print_ads_id: dataToSubmit.print_ads_id,
          subtotal: dataToSubmit.subtotal,
          ec_total: dataToSubmit.ec_total,
          grand_total: dataToSubmit.grand_total,
          voucher_id: dataToSubmit.voucher_id,
          discount: dataToSubmit.discount,
          tax: dataToSubmit.tax,
          // payment_link: ,
          job_ads_id: res.data.id,
          company_id: dataToSubmit.company_id,
          order_plans: dataToSubmit.order_plans,
          addOns: dataToSubmit.addOns,
          print_price: dataToSubmit.print_price,
          package_id: dataToSubmit.package_id,
          post_payment_url: dataToSubmit.post_payment_url,
          plan_id: null,
          is_pending_payment: false,
        },
        {
          plan: dataToSubmit.plan,
        },
        paymentGatewayCallback
      );
    };
    createJobAdError = (error) => requestError(error);

    createJobAdsBenefit = (dataToSubmit, callback) => {
      Post(
        `/jobads-benefits`,
        dataToSubmit,
        payload => this.createJobAdsBenefitSuccess(payload, callback),
        (error) => requestError(error),
        this.load
      );
    };
    createJobAdsBenefitSuccess = (payload, callback) => {
      if(callback) callback(payload.data);
      // requestSuccess("Job Ad Benefit created successfully.");
    };

    deleteJobAdsBenefits = (id, callback) => Delete(
      `/jobads-benefits/${id}`,
      response => this.deleteJobAdsBenefitsSuccess(response, callback),
      error => requestError( error ),
      this.load,
      true
    )
    deleteJobAdsBenefitsSuccess = (response, callback) => {
      callback()
      requestSuccess("Job Ad Benefit deleted successfully.")
    }

    createJobAdsGallery = (dataToSubmit, callback) => {
      Post(
        `/jobads-galleries`,
        dataToSubmit,
        payload => this.createJobAdsGallerySuccess(payload, callback),
        (error) => requestError(error),
        this.load
      );
    };
    createJobAdsGallerySuccess = (payload, callback) => {
      if(callback) callback(payload.data);
      // requestSuccess("Job Ad Gallery created successfully.");
    };

    deleteJobAdsGallery = (id, callback) => Delete(
      `/jobads-galleries/${id}`,
      response => this.deleteJobAdsGallerySuccess(response, callback),
      error => requestError( error ),
      this.load,
      true
    )
    deleteJobAdsGallerySuccess = (response, callback) => {
      callback()
      requestSuccess("Job Ad Gallery deleted successfully.")
    }

    createJobAdsBranch = (dataToSubmit, callback) => {
      Post(
        `/jobads/${dataToSubmit.job_ads_id}/job-locations`,
        dataToSubmit,
        payload => this.createJobAdsBranchSuccess(payload, callback),
        (error) => requestError(error),
        this.load
      );
    };
    createJobAdsBranchSuccess = (payload, callback) => {
      callback(payload.data);
      requestSuccess("Job Ad Branch created successfully.");
    };

    updateJobAdsBranch = (data) => Put(
      `/jobads/${data.job_ads_id}/job-locations/${data.id}`,
      data,
      this.updateJobAdsBranchSuccess,
      error => requestError( error ),
      this.load,
      true
    )
    updateJobAdsBranchSuccess = () => requestSuccess("Job Ad Branch updated successfully.");

    deleteJobAdsBranch = (data, callback) => Delete(
      `/jobads/${data.job_ads_id}/job-locations/${data.id}`,
      response => this.deleteJobAdsBranchSuccess(response, callback),
      error => requestError( error.message ),
      this.load,
      true
    )
    deleteJobAdsBranchSuccess = (response, callback) => {
      callback()
      requestSuccess("Job Ad Branch deleted successfully.")
    }

    createJobAdsOrder = (dataToSubmit, extraData, paymentGatewayCallback) => {
      const ordersData = {
        ...dataToSubmit,
        ...extraData,
      };

      Post(
        "/orders",
        dataToSubmit,
        (res) =>
          this.createJobAdsOrderSuccess(
            ordersData,
            res,
            paymentGatewayCallback
          ),
        (error) => requestError(error),
        this.load
      );
    };

    createJobAdsOrderSuccess = (dataToSubmit, res, paymentGatewayCallback) => {
      if (dataToSubmit.plan.length > 0) {
        const uniquePlans = [...new Set(dataToSubmit.plan)];

        const promises = uniquePlans.map((plan) => {
          return new Promise((resolve) => {
            this.createJobAdsOrderPlan({
              order_id: res.data.id,
              plan_id: plan,
              quantity: dataToSubmit.plan.filter((item) => item === plan)
                .length,
              onComplete: resolve, // Resolve the promise when the task is complete
            });
          });
        });
        
        Promise.all(promises).then(() => {
          setTimeout(function () {
            requestSuccess("Order is created successfully");
          }, 1000);
          
          if(paymentGatewayCallback) paymentGatewayCallback()
        });
      } else {
        setTimeout(function () {
          requestSuccess("Order is created successfully");
        }, 1000);

        if(paymentGatewayCallback) paymentGatewayCallback()
      }
    };

    getPaymentKey = (dataToSubmit, callback) => {
      Post(
        `/payment-gateway/response`,
        dataToSubmit,
        (payload) => this.getPaymentKeySuccess(payload, callback),
        (error) => requestError(error),
        this.load
      );
    };

    getPaymentKeySuccess = (payload, callback) => {
      this.setState({ payment_key: payload.data }, () =>
        callback(payload.data)
      );
    };

    createJobAdsOrderPlan = ({ order_id, plan_id, quantity, onComplete }) => {
      Post(
        "/order-plans",
        {
          order_id: order_id,
          plan_id: plan_id,
          quantity: quantity,
        },
        () => {
          if (typeof onComplete === "function") {
            onComplete();
          }
        },
        (error) => requestError(error),
        this.load
      );
    };

    getAdvertiser = () => {
      Get(
        "/companies",
        this.getAdvertiserSuccess,
        this.getAdvertiserError,
        this.load
      );
    };
    getAdvertiserSuccess = (payload) => {
      this.setState({
        advertiserList: payload.data.rows,
      });
    };
    getAdvertiserError = (error) => requestError(error);

    getAgentList = () => {
      Get(
        "/users?role=Agent&platform=jobmacha",
        this.getAgentListSuccess,
        this.getAgentListError,
        this.load
      )
    }
    getAgentListSuccess = (payload) => {
      let temp = payload.data?.map((item) => ({
        ... item,
        label: item.name,
        value: item.id
      }))
      this.setState({agentList: temp});
    }

    getNature = () => {
      Get(
        "/jobads/specializations",
        this.getNatureSuccess,
        this.getNatureError,
        this.load
      );
    };
    getNatureSuccess = (payload) => {
      this.setState({
        natureList: payload.data,
      });
    };
    getNatureError = (error) => requestError(error);

    getEducationLevel = () => {
      Get(
        "/jobads/education-levels",
        this.getEducationLevelSuccess,
        this.getEducationLevelError,
        this.load
      );
    };
    getEducationLevelSuccess = (payload) => {
      this.setState({
        educationLevelList: payload.data,
      });
    };
    getEducationLevelError = (error) => requestError(error);

    getPositionLevel = () => {
      Get(
        "/jobads/position-levels",
        this.getPositionLevelSuccess,
        this.getPositionLevelError,
        this.load
      );
    };
    getPositionLevelSuccess = (payload) => {
      this.setState({
        positionLevelList: payload.data,
      });
    };
    getPositionLevelError = (error) => requestError(error);

    getPackage = () =>
      Get("/packages", this.getPackageSuccess, this.getPackageError, this.load);
    getPackageSuccess = (payload) => {
      this.setState({
        packageList: payload.data,
      });
    };
    getPackageError = (error) => requestError(error);

    getPlan = () =>
      Get("/plans", this.getPlanSuccess, this.getPlanError, this.load);
    getPlanSuccess = (payload) => {
      this.setState({
        planList: payload.data,
      });
    };
    getPlanError = (error) => requestError(error);

    getRegions = () => 
        Get("/regions", this.getRegionsSuccess, this.getRegionsError, this.load);
    getRegionsSuccess = (payload) => {
        let tempData = payload.data.map((item) => {
            return {
                label: item.name.en,
                value: item.id
            }
        });
        this.setState({ regionsList: tempData });
    }
    getRegionsError = (error) => requestError(error);

    getCountry = () =>
      Get(
        "/countries",
        this.getCountrySuccess,
        this.getCountryError,
        this.load
      );
    getCountrySuccess = (payload) => {
        const sortCountries = () => {
            let countries = payload.data;
        
            const malaysiaIndex = countries.findIndex(country => country.id === COUNTRY_MALAYSIA_ID);
            const malaysia = countries[malaysiaIndex]
            countries.splice(malaysiaIndex, 1);
        
            const singaporeIndex = countries.findIndex(country => country.id === COUNTRY_SINGAPORE_ID); 
            const singapore = countries[singaporeIndex]
            countries.splice(singaporeIndex, 1);

            return [
                malaysia,
                singapore,
                ...countries.sort((a, b) => a.name - b.name)
            ]
        }
        
        this.setState({
            countriesList: sortCountries(),
        });
    };
    getCountryError = (error) => requestError(error);

    getState = (id) =>
      Get(
        `/countries/${id}/states`,
        this.getStateSuccess,
        this.getStateError,
        this.load
      );
    getStateSuccess = (payload) => {
      this.setState({
        statesList: payload.data,
      });
    };
    getStateError = (error) => requestError(error);

    validateVoucher = (voucherCode, validateVoucherSuccess) => {
      Get(
        `/validate-voucher?platform=jobmacha&code=${voucherCode}`,
        (payload) => {
          validateVoucherSuccess(payload);
        },
        (error) => {
          requestError(error);
        },
        this.load
      );
    };

    downloadImage = id => {
        GetFile(
          `/jobads-gen-image/${id}`,
          `generate_as_image_${id}_en.png`,
          () => requestSuccess('English Image Downloaded'),
          error => requestError(error),
          this.load,
          'en-US'
        );
      
        GetFile(
          `/jobads-gen-image/${id}`,
          `generate_as_image_${id}_zh.png`,
          () => requestSuccess('Chinese Image Downloaded'),
          error => requestError(error),
          this.load,
          'zh-CN'
        );
    }

    load = (param) => this.setState({ loading: param });

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          loading={this.state.loading}
          getPlan={this.getPlan}
          getState={this.getState}
          getNature={this.getNature}
          getCountry={this.getCountry}
          getPackage={this.getPackage}
          getRegions={this.getRegions}
          onChangeHOC={this.onChangeHOC}
          createJobAd={this.createJobAd}
          saveAsDraft={this.saveAsDraft}
          getBenefits={this.getBenefits}
          getAgentList={this.getAgentList}
          getAdvertiser={this.getAdvertiser}
          getSelectedPost={this.getSelectedPost}
          validateVoucher={this.validateVoucher}
          getPositionLevel={this.getPositionLevel}
          getEducationLevel={this.getEducationLevel}
          createJobAdsGallery={this.createJobAdsGallery}
          deleteJobAdsGallery={this.deleteJobAdsGallery}
          createJobAdsBenefit={this.createJobAdsBenefit}
          deleteJobAdsBenefits={this.deleteJobAdsBenefits}
          createJobAdsBranch={this.createJobAdsBranch}
          updateJobAdsBranch={this.updateJobAdsBranch}
          deleteJobAdsBranch={this.deleteJobAdsBranch}         
          downloadImage={this.downloadImage}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    setProfile,
  })(WithHOC);
};
export default HOC;
