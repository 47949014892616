import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

export default function FilterForm({ onSubmit, onChangeHOC, handleState }) {
	const [activatedDate, setActivatedDate] = useState("");
	const [transInvoice, setTransInvoice] = useState("");
	const [postID, setPostID] = useState("");
	const [advertiser, setAdvertiser] = useState("");
	const [invoiceNumber, setInvoiceNumber] = useState("");
	const [agentLeadID, setAgentLeadID] = useState("");
	const [promoCode, setPromoCode] = useState("");
	const [agentID, setAgentID] = useState("");
	const [agentName, setAgentName] = useState("");
	const [agentLeadName, setAgentLeadName] = useState("");
	const [dateOfEditing, setDateOfEditing] = useState("");
	const [priceAfterTax, setPriceAfterTax] = useState("");
	const [sellingPrice, setSellingPrice] = useState("");

	const handleClear = () => {
		setAdvertiser("");
		setInvoiceNumber("");
		setAgentName("");
		setActivatedDate("");
		setTransInvoice("");
		setAgentLeadID("");
		setPromoCode("");
		setPostID("");
		setAgentLeadName("");
		setAgentID("");
		setDateOfEditing("");
		setPriceAfterTax("");
		setSellingPrice("");
	};

	const generateQuery = () => {
		let queryString = "";

		if (postID){ queryString += `&jobads_id=${postID}`}
		if (agentName){ queryString += `&agent_name=${agentName}`}
		if (agentLeadID){ queryString += `&agent_code=${agentLeadID}`}
		if (agentLeadName){ queryString += `&last_edit_user=${agentLeadName}`}
		if (advertiser){ queryString += `&advertiser_name=${advertiser}`}
		if (transInvoice){ queryString += `&invoice_date=${transInvoice}`}
		if (dateOfEditing){ queryString += `&date_of_editing=${dateOfEditing}`}
		if (priceAfterTax){ queryString += `&price_after_tax=${priceAfterTax}`}
		if (sellingPrice){ queryString += `&selling_price=${sellingPrice}`}
		// havent support activate date
		// if (activatedDate){ queryString += `&activated_date=${activatedDate}`}

		return queryString;
	};

	return (
		<>
			<CustomModalBody>
				<Form id="billing-filter-form">
					<Row>
						<Col lg={3}>
							<FormGroup>
								<Label for="postID">Post ID </Label>
								<Input
									placeholder="Enter Post ID"
									name="postID"
									id="postID"
									value={postID}
									onChange={(e) => {setPostID(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="transInvoice">Invoice Date</Label>
								<Input
									type="date"
									placeholder="Enter Invoice Date"
									name="transInvoice"
									id="transInvoice"
									value={transInvoice}
									onChange={(e) => {setTransInvoice(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="setDateOfEditing">Last Modified Date</Label>
								<Input
									type="date"
									placeholder="Enter Last Modified Date"
									name="setDateOfEditing"
									id="setDateOfEditing"
									value={dateOfEditing}
									onChange={(e) => {setDateOfEditing(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="advertiser">Advertiser Name </Label>
								<Input
									placeholder="Enter Advertiser Name"
									name="advertiser"
									id="advertiser"
									value={advertiser}
									onChange={(e) => {setAdvertiser(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="sprice">Selling Price</Label>
								<Input
									placeholder="Enter Selling Price"
									name="sprice"
									id="sprice"
									value={sellingPrice}
									onChange={(e) => {setSellingPrice(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="priceat">Price After Tax</Label>
								<Input
									placeholder="Enter Price After Tax"
									name="priceat"
									id="priceat"
									value={priceAfterTax}
									onChange={(e) => {setPriceAfterTax(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						
						<Col lg={3}>
							<FormGroup>
								<Label for="agentName">Agent Name </Label>
								<Input
									placeholder="Enter Agent Name"
									name="agentName"
									id="agentName"
									value={agentName}
									onChange={(e) => {setAgentName(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentLeadID">Agent Code </Label>
								<Input
									placeholder="Enter Agent Code"
									name="agentLeadID"
									id="agentLeadID"
									value={agentLeadID}
									onChange={(e) => {setAgentLeadID(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentLeadName">Last Modified User </Label>
								<Input
									placeholder="Enter Last Modified User"
									name="agentLeadName"
									id="agentLeadName"
									value={agentLeadName}
									onChange={(e) => {setAgentLeadName(e.target.value)}}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "danger",
						outline: true,
						content: "Clear All",
						onClick: () => handleClear(),
					},
					{
						color: "primary",
						content: "Submit",
						onClick: () => {
							const queryString = generateQuery();
							onSubmit(queryString);
							handleState({ filterQuery: queryString });
							onChangeHOC("showFilterModal", false);
							onChangeHOC("advertiserPages", 1);
						},
					},
				]}
			/>
		</>
	);
}
