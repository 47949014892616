import React, { useState } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";

export default function FilterForm({ onSubmit, onChangeHOC, handleState, packageList }) {
  const [advertiserId, setAdvertiserId] = useState("");
  const [postId, setPostId] = useState("");
  const [ecId, setEcId] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [advertiser, setAdvertiser] = useState("");
  const [orderDescriptions, setOrderDescriptions] = useState("");
  const [agentCode, setAgentCode] = useState("");
  const [agentName, setAgentName] = useState("");
  const [settlementAmount, setSettlementAmount] = useState("");
  const [proformaInvoice, setProformaInvoice] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [approvalDate, setApprovalDate] = useState("");
  const [offerPackage, setOfferPackage] = useState("");
  const [transactionReference, setTransactionReference] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [merchantReference, setMerchantReference] = useState("");
  const [plan, setPlan] = useState("");

  const handleClear = () => {
    setAdvertiserId("");
    setPostId("");
    setEcId("");
    setOrderNumber("");
    setAdvertiser("");
    setOrderDescriptions("");
    setAgentCode("");
    setAgentName("");
    setSettlementAmount("");
    setProformaInvoice("");
    setInvoiceNumber("");
    setReceiptNumber("");
    setApprovalDate("");
    setOfferPackage("");
    setTransactionReference("");
    setPaymentMethod("");
    setMerchantReference("");
    setPlan("");

    onSubmit("");
    onChangeHOC("showFilterModal", false);
  };

  const generateQuery = () => {
    let queryString = "";

    if (advertiserId) {
      queryString += `&company_id=${advertiserId}`;
    }
    if (postId) {
      queryString += `&jobads_platform_uuid=${postId}`;
    }
    if (ecId) {
      queryString += `&ec_id=${ecId}`;
    }
    if (orderNumber) {
      queryString += `&id=${orderNumber}`;
    }
    if (orderDescriptions) {
      queryString += `&description=${orderDescriptions}`;
    }
    if (proformaInvoice) {
      queryString += `&proforma_invoice_number=${proformaInvoice}`;
    }
    if (invoiceNumber) {
      queryString += `&invoice_number=${invoiceNumber}`;
    }
    if (agentCode) {
        queryString += `&agent_id=${agentCode}`;
    }
    if (agentName) {
        queryString += `&agent_name=${agentName}`;
    }
    if (settlementAmount) {
        queryString += `&grand_total=${settlementAmount}`;
    }
    if (receiptNumber) {
        queryString += `&receipt_number=${receiptNumber}`;
    }
    if (approvalDate) {
        queryString += `&published_date_start=${approvalDate}`;
    }
    if (transactionReference) {
        queryString += `&payment_ref_no=${transactionReference}`;
    }
    if (paymentMethod) {
        queryString += `&payment_method=${paymentMethod}`;
    }
    if (merchantReference) {
        queryString += `&merchant_reference=${merchantReference}`;
    }
    if (plan) {
        queryString += `&package_id=${plan}`;
    }

    return queryString;
  };

  return (
    <>
      <CustomModalBody>
        <Form id="billing-filter-form">
          <Row>
            <Col lg={3}>
              <FormGroup>
                <Label for="advertiserId">Advertiser ID</Label>
                <Input
                  placeholder="Enter Advertiser ID"
                  name="advertiserId"
                  id="advertiserId"
                  value={advertiserId}
                  onChange={(e) => setAdvertiserId(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="message">Post ID</Label>
                <Input
                  placeholder="Enter Post ID"
                  name="postId"
                  id="postId"
                  value={postId}
                  onChange={(e) => setPostId(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="message">EC ID</Label>
                <Input
                  placeholder="Enter EC ID"
                  name="ecId"
                  id="ecId"
                  value={ecId}
                  onChange={(e) => setEcId(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="orderNumber">Order No.</Label>
                <Input
                  placeholder="Enter Order No."
                  name="orderNumber"
                  id="orderNumber"
                  value={orderNumber}
                  onChange={(e) => setOrderNumber(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="package_id">Plan</Label>
                <Input
                  type="select"
                  name="package_id"
                  id="package_id"
                  value={plan}
                  onChange={(e) => setPlan([e.target.value])}
                >
                  <option disabled value="">
                    Select Plan
                  </option>
                  {packageList.map((nature) => (
                    <option value={nature.id}>{nature.name.en}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="openCount">Advertiser Name</Label>
                <Input
                  placeholder="Enter Advertiser Name"
                  name="advertiser"
                  id="advertiser"
                  value={advertiser}
                  onChange={(e) => setAdvertiser(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="clickCount">Order Descriptions</Label>
                <Input
                  placeholder="Enter Order Descriptions"
                  name="orderDescriptions"
                  id="orderDescriptions"
                  value={orderDescriptions}
                  onChange={(e) => setOrderDescriptions(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="clickCount">Agent ID</Label>
                <Input
                  placeholder="Enter Agent ID"
                  name="agentCode"
                  id="agentCode"
                  value={agentCode}
                  onChange={(e) => setAgentCode(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="clickCount">Agent Name</Label>
                <Input
                  placeholder="Enter Agent Name"
                  name="agentName"
                  id="agentName"
                  value={agentName}
                  onChange={(e) => setAgentName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="clickCount">Grand Total</Label>
                <Input
                  placeholder="Enter Grand Total"
                  name="settlementAmount"
                  id="settlementAmount"
                  value={settlementAmount}
                  onChange={(e) => setSettlementAmount(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="paymentMethod">Payment Method</Label>
                <Input
                  placeholder="Enter Payment Method"
                  name="paymentMethod"
                  id="paymentMethod"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="transactionReference">Transaction Reference</Label>
                <Input
                  placeholder="Enter Transaction Reference"
                  name="transactionReference"
                  id="transactionReference"
                  value={transactionReference}
                  onChange={(e) => setTransactionReference(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="merchantReference">Merchant Reference</Label>
                <Input
                  placeholder="Enter Merchant Reference"
                  name="merchantReference"
                  id="merchantReference"
                  value={merchantReference}
                  onChange={(e) => setMerchantReference(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="clickCount">Proforma Invoice</Label>
                <Input
                  placeholder="Enter Proforma Invoice"
                  name="proformaInvoice"
                  id="proformaInvoice"
                  value={proformaInvoice}
                  onChange={(e) => setProformaInvoice(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="clickCount">Invoice Number</Label>
                <Input
                  placeholder="Enter Invoice Number"
                  name="invoiceNumber"
                  id="invoiceNumber"
                  value={invoiceNumber}
                  onChange={(e) => setInvoiceNumber(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="clickCount">Receipt Number</Label>
                <Input
                  placeholder="Enter Receipt Number"
                  name="receiptNumber"
                  id="receiptNumber"
                  value={receiptNumber}
                  onChange={(e) => setReceiptNumber(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="status">Published Date</Label>
                <Input
                  type="date"
                  name="approvalDate"
                  id="approvalDate"
                  value={approvalDate}
                  onChange={(e) => setApprovalDate(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            color: "danger",
            outline: true,
            content: "Clear All",
            onClick: () => handleClear(),
          },
          {
            color: "primary",
            content: "Submit",
            onClick: () => {
              const queryString = generateQuery();
              onSubmit(queryString);
              handleState({ filterQuery: queryString });
              onChangeHOC("showFilterModal", false);
              onChangeHOC("advertiserPages", 1);
            },
          },
        ]}
      />
    </>
  );
}
