import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Put, GetFile, Post } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      specializations: [],
      postList: [],
      postPages: -1,
      selectedPost: null,
      selectedPostType: "All",
      showEditSampleModal: false,
      showFilterModal: false,
      showConfirmModal: false,
      showDisableModal: false,
      showEnableModal: false,
      showUpgradeModal: false,
      showPreviewModal: false,
      showDuplicateModal: false,
    };

    onChangeHOC = (key, val) => this.setState({ [key]: val });

    load = (param) => this.setState({ loading: param });

    getSpecializations = () => Get(
      `/jobads/specializations?type=jobs`,
      this.getSpecializationsSuccess,
      error => requestError( error ),
      this.load
    )
    getSpecializationsSuccess = response => {
      if( response?.data.length > 0 ){
        let tempData = response.data.flatMap( specialization => {
          return [
            {
              en: specialization?.name?.en,
              zh: specialization?.name?.zh,
              value: specialization?.id,
            },
            ...specialization?.children?.map( child => {
              return {
                en: '- ' + child?.name?.en,
                zh: child?.name?.zh,
                value: child?.id
              }
            })
          ]
        })
        this.setState({ specializations: tempData })
      }
    }

    getPost = (query = "") => {
      const route = `/job-ads?limit=10&type=job${!!query ? query : ""}`;

      Get(route, this.getPostSuccess, this.getPostError, this.load);
    };
    getPostSuccess = (payload) => {
      this.setState({
        postList: payload.data,
        postPages: Math.ceil(payload.count / 10),
        loading: false,
      });
    };
    getPostError = (error) => requestError(error);

    getSelectedPost = (slug) => {
      Get(
        `/job-ads/single?slug=${slug}`,
        this.getSelectedPostSuccess,
        this.getSelectedPostError,
        this.load
      );
    }
    getSelectedPostSuccess = (payload) => {
      this.setState({
        selectedPost: payload.data?.[0],
        showPreviewModal: true,
        loading: false,
      });
    };
    getSelectedPostError = (error) => requestError(error);

    downloadImage = id => {
      GetFile(
        `/jobads-gen-image/${id}`,
        `generate_as_image_${id}_en.png`,
        () => requestSuccess('English Image Downloaded'),
        error => requestError(error),
        this.load,
        'en-US'
      );
    
      GetFile(
        `/jobads-gen-image/${id}`,
        `generate_as_image_${id}_zh.png`,
        () => requestSuccess('Chinese Image Downloaded'),
        error => requestError(error),
        this.load,
        'zh-CN'
      );
    }

    updateOrderPlan = (input) => {
      Put(
        `/order-plans/${input.id}`,
        input,
        () => requestSuccess('Saved'),
        (error) => requestError(error),
        () => {
          this.setState({
            selectedPost: this.state.selectedPost
          })
        }
      );
    };

    suspendPost = (postId) => {
      Put(
        `/job-ads/suspend/${postId}`,
        {},
        this.suspendPostSuccess,
        this.suspendPostError,
        this.load
      );
    };

    suspendPostSuccess = () => {
      this.getPost();
      this.setState({
        showConfirmModal: false,
      });
      requestSuccess("Post suspended successfully.");
    };

    suspendPostError = (error) => {
      requestError(error);
    };

    duplicatePost = async (id) => Post(
      `/job-ads/duplicate/${id}`,
      {},
      this.duplicatePostSuccess,
      error => requestError( error ),
      this.load,
      true
    )
    duplicatePostSuccess = payload => {
      this.setState({ showDuplicateModal: false })
      requestSuccess("Post duplicated successfully.")
      this.getPost();
    }

    updateSample = (dataToSubmit) => {
      Put(
        `/mywheels/samples/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateSampleSuccess,
        this.updateSampleError,
        this.load
      );
    };
    updateSampleSuccess = () => {
      this.getSample();
      requestSuccess("Record updated successfully.");
    };
    updateSampleError = (error) => requestError(error);

    disableOrder = (id) => {
        Put(
          `/job-ads/disable/${id}`,
          {},
          this.disableOrderSuccess,
          error => requestError( error ),
          this.load
        );
    };
    disableOrderSuccess = () => {
        this.setState({ showDisableModal: false });
        requestSuccess("Record updated successfully.");
        this.getPost();
    };

    enableOrder = (id) => {
        Put(
          `/job-ads/enable/${id}`,
          {},
          this.enableOrderSuccess,
          (error) => requestError(error),
          this.load
        );
    };
    enableOrderSuccess = () => {
        this.setState({ showEnableModal: false });
        requestSuccess("Record updated successfully.");
        this.getPost();
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          specializations={this.state.specializations}
          postList={this.state.postList}
          postPages={this.state.postPages}
          selectedPost={this.state.selectedPost}
          showEditSampleModal={this.state.showEditSampleModal}
          selectedPostType={this.state.selectedPostType}
          onLoadPost={this.state.loading}
          getPost={this.getPost}
          getSpecializations={this.getSpecializations}
          suspendPost={this.suspendPost}
          updateOrderPlan={this.updateOrderPlan}
          updateSample={this.updateSample}
          disableOrder={this.disableOrder}
          enableOrder={this.enableOrder}
          onChangeHOC={this.onChangeHOC}
          downloadImage={this.downloadImage}
          duplicatePost={this.duplicatePost}
          getSelectedPost={this.getSelectedPost}
          showFilterModal={this.state.showFilterModal}
          showPreviewModal={this.state.showPreviewModal}
          showConfirmModal={this.state.showConfirmModal}
          showDisableModal={this.state.showDisableModal}
          showUpgradeModal={this.state.showUpgradeModal}
          showDuplicateModal={this.state.showDuplicateModal}
          showEnableModal={this.state.showEnableModal}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
